.App {
  text-align: center;
  min-height: 100vh;
}

html, body, #root, .App {
  height: 100%;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
img {
  margin: 5px;
  max-width: -webkit-fill-available;
  max-width: fill-available;
}

.card--list {
  margin-top: 4rem;
}

.background {
   background-image: url("background.jpg");
}


.card {
  padding:  2rem 4rem;
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.25);
  margin-bottom: 2rem;
  background-color: white;
  display: flex;

}

.card--image {
  margin: 0 auto;
  display: block;
  width: 120px;
}

.white-box {
  border: 2px solid #d1914c;
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  color: #d1914c;
}