@font-face {
    font-family: 'CeraGR';
    src: url('CeraGR-Regular.woff2') format('woff2'),
        url('CeraGR-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CeraGR';
    src: url('CeraGR-Medium.woff2') format('woff2'),
        url('CeraGR-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bell';
    src: url('Bell-Med.woff2') format('woff2'),
        url('Bell-Med.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CeraGR';
    src: url('CeraGR-Light.woff2') format('woff2'),
        url('CeraGR-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

